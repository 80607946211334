import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, menuLinks }) => (
    <nav className="navbar navbar-expand-md bg-secondary fixed-top text-uppercase" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="/">
          <span className="ten-minutes">Ten Minutes</span>
          <span className="mama">for Mama</span>
        </a>
        <div id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            {menuLinks.map(link => (
                <li  className="nav-item mx-0 mx-lg-1" key={link.name}>
                  <Link className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" to={link.link}>
                    {link.name}
                  </Link>
                </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
