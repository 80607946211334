import * as React from "react"

const Footer = () => (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Location</h4>
            <p className="lead mb-0">Brisbane, Australia</p>
          </div>
          <div className="col-md-4 mb-5 mb-lg-0">
            <h4 className="text-uppercase mb-4">Around the Web</h4>
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a className="btn btn-outline-light btn-social text-center rounded-circle"
                   href="https://www.facebook.com/tenminutesformama/">
                  <i className="fa fa-fw fa-facebook"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a className="btn btn-outline-light btn-social text-center rounded-circle"
                   href="https://www.instagram.com/tenminutesformama/">
                  <i className="fa fa-fw fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h4 className="text-uppercase mb-4">Disclaimer</h4>
            <p className="mb-0">These activities were designed for my own children. They may not
              be appropriate for all kids.</p>
          </div>
        </div>
      </div>

        <div className="copyright py-4 text-center text-white">
            <div className="container">
            <small>Copyright &copy; Hot Currie Pty Ltd 2018</small>
            </div>
        </div>

        {/*  Scroll to Top Button (Only visible on small and extra-small screen sizes) */}
        <div className="scroll-to-top d-lg-none position-fixed ">
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fa fa-chevron-up"></i>
          </a>
        </div>
  </footer>
)

export default Footer
